/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import avatar from "../../content/assets/profile-pic.jpg"
import instagram from "../../content/assets/icons/instagram.svg"
import twitter from "../../content/assets/icons/twitter.svg"
import rss from "../../content/assets/icons/rss.svg"
import weibo from "../../content/assets/icons/weibo.svg"
import film from "../../content/assets/icons/film.svg"
import headphones from "../../content/assets/icons/headphones.svg"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter,
            instagram,
            rss,
            weibo
          }
        }
      }
    }
  `)

  const { author, social } = data.site.siteMetadata
  return (
    <div className="bio">
      <div className="avatar">
        <img alt="avatar" src={avatar}/>
      </div>
      <div className="info">
        <p>{author.summary}</p>
        <div className="social-list">
          <a href={social.instagram} target="blank"><img src={instagram} alt="instagram" width={14} height={14} /></a>
          <a href={social.twitter} target="blank"><img src={twitter} alt="instagram" width={14} height={14} /></a>
          <a href={social.weibo} target="blank"><img src={weibo} alt="instagram" width={16} height={16} /></a>
          <Link to="/movies"><img src={film} alt="movies" width={14} height={14} /></Link>
          <Link to="/musics"><img src={headphones} alt="music" width={14} height={14} /></Link>
          <a href={social.rss} target="blank"><img src={rss} alt="instagram" width={14} height={14} /></a>
        </div>
      </div>
    </div>
  )
}

export default Bio
